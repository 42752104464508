<template>
    <v-container>
        <v-card color="white" rounded class="py-8 px-16" elevation="2">
            <v-row justify="space-between" align="center" class="d-flex">
                <v-col cols="12" sm="6" class="text-right">
                    <h3 class="secondary--text mb-1">تیم کریپتوجِت</h3>
                    <p>راز موفقیت فعلی کریپتوجِت در چیست؟</p>
                    <p class="text-justify">
                        افتخار می کنیم که یکی از سخت کوش ترین و با استعدادترین تیم های ایران را داریم.کریپتوجِت با کمک تیم پویای خود همواره کوشیده پیشرفته‌ترین و فناورانه‌ترین قابلیت‌ها و ویژگی‌ها را همگام با بازارهای جهانی در اختیار کاربران قرار دهد.
                    </p>
					<p>تغییر،تحول و پایداری کارساده ای نیست ، اما به شما قول میدهیم که همیشه در حال رشد و یادگیری خواهیم بود و دانش این حوزه را نیز با شما به اشتراک خواهیم گذاشت.
					</p>				
                    <p>دوست دارید به تیم کریپتوجِت ملحق شوید؟</p>
                    <v-btn color="secondary" small class="pb-1">فرصت های شغلی</v-btn>
                </v-col>
                <v-col cols="12" sm="6">
                    <v-img :src="src" />
                </v-col>
            </v-row>
        </v-card>
    </v-container>
</template>

<script>
    export default {
        data(){
            return{
                src:'./assets/img/about/a2.webp',
            }
        }
    }
</script>