import axios from "axios"

export default {
    // Get Crypto CUrrrency 
    async getCurrency({ commit }) {
        return new Promise((resolve, reject) => {
            axios({ 'url': 'https://cryptojet.mentorvid.com/api/prices', method: 'GET' })
                .then(resp => {
                    resolve(resp.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },

    // Get Posts
    async getPosts({ commit }) {
        return new Promise((resolve, reject) => {
            axios({ 'url': 'https://mentorvid.com/wp-json/wp/v2/posts', method: 'GET' })
                .then(resp => {
                    resolve(resp.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
}