<template>
    <v-container>
        <v-card color="white" rounded class="py-8 px-16" elevation="2">
            <v-row justify="center" align="center" class="secondary--text">
                <h2>ارزش های کریپتوجِت</h2>
            </v-row>
            <v-row justify="center" align="start">
                <v-col v-for="(item, i) in items" :key="i" cols="12" sm="3" class="hover text-center">
                    <v-avatar size="100">
                        <v-icon x-large>{{ item.icon }}</v-icon>
                    </v-avatar>
                    <p class="textSubtitle">{{ item.subject }}</p>
                </v-col>
            </v-row>
        </v-card>
    </v-container>
</template>

<script>
    export default {
        data(){
            return{
                items:[
                    { icon:'mdi-microsoft-teams', subject:'تعهد و تخصص تیم کریپتوجِت' },
                    { icon:'mdi-shield', subject:'محافظت و امانت داری از دارایی های مشتریان' },
                    { icon:'mdi-wechat', subject:'پاسخگویی به نیازها و پرسش های مشتریان به بهترین شکل ممکن' }
                ]
            }
        }
    }
</script>