<template>
    <section v-if="$route.name == 'home'">
        <price-slider />
        <Banner />
        <card-slider />
        <Feature />
        <Guide />
        <contact-us />
    </section>
</template>

<script>
    import PriceSlider from './PriceSlider.vue';
    import Banner from './Banner.vue';
    import CardSlider from './PostSlider.vue';
    import Feature from './Feature.vue';
    import Guide from './Guide.vue';
    import ContactUs from './ContactUs.vue';
    export default {
          components:{
            PriceSlider,
            Banner,
            CardSlider,
            Feature,
            Guide,
            ContactUs,
        }
    }
</script>
