<template>
    <section>
        <v-row justify="center" align="center">
            <v-col cols="9">
                <v-card height="auto" class="mb-3">
                    <v-card-text class="d-flex flex-wrap justify-space-between align-center">
                        <v-col cols="auto">
                            <v-img :src="step4" width="200" height="190" />
                        </v-col>
                        <v-col cols="12" sm="6">
                            <div class="d-flex flex-wrap py-4">
                                <v-chip color="primary" class="ml-3 mb-2">مسیر چهارم</v-chip>
                                <h1 class="secondary--text">تماس با بخش امور مشتریان</h1>
                            </div>
                            <h4 class="black--text pb-4 text-justify">با توجه به حجم بالای تماس ها ممکن است امکان پاسخگویی به تمام درخواست های شما وجود نداشته باشد. لذا خواهشمند است تا جای ممکن سوالات و مشکلات خود را با پشتیبانی آنلاین کریپتوجِت مطرح کنید.</h4>
                            <div class="mb-3">
                                <v-icon class="ml-2" color="secondary">mdi-clock-time-eight-outline</v-icon>
                                <span>7 روز هفته از ساعت 9 الی 21:00 پشتیبان شماییم</span>
                            </div>
                            <div>
                                <v-icon class="ml-2" color="secondary">mdi-phone-hangup</v-icon>
								
							<v-btn class="hover pb-1" href="tel:09120123244" text><span>0123244 - 0912 </span></v-btn>
								
								
							
								
                            </div>
							<div>
                                <v-icon class="ml-2" color="secondary">mdi-map-marker-multiple-outline</v-icon><p>تهران | تجریش | ساختمان نصر </p>
                            </div>
							
                        </v-col>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </section>
</template>

<script>
    export default {
        data(){
            return{
                step4:'./assets/img/contact/step4.webp'
            }
        }
    }
</script>