<template>
    <section>
        <v-row justify="center" align="center">
            <v-col cols="9">
                <v-card height="auto" class="mb-3">
                    <v-card-text class="d-flex flex-wrap justify-space-between align-center">
                        <v-col cols="12" sm="6">
                            <div class="d-flex flex-wrap py-4">
                                <v-chip color="primary" class="ml-3 mb-2">مسیر سوم</v-chip>
                                <h1 class="secondary--text">کانال اطلاع‌رسانی</h1>
                            </div>
                            <h4 class="black--text pb-4 text-justify">برای دریافت آخرین اطلاعیه‌ها و اخبار کریپتوجِت، کانال رسمی ما را در تلگرام دنبال کنید.</h4>
                            <v-btn color="secondary"     href="https://t.me/mentorvid_ir"   small>عضویت در کانال تلگرام</v-btn>
                        </v-col>
                        <v-col cols="auto">
                            <v-img :src="step3" width="200" height="190" />
                        </v-col>
                    </v-card-text>
                    <v-card-text>
                        <v-alert outlined type="warning" text>
                            کریپتوجِت هیچ‌گونه فعالیت پشتیبانی در پیام‌‌رسان‌های تلگرام و واتس آپ ندارد و تنها کانال رسمی اطلاع رسانی کریپتوجِت به آدرس mentorvid_ir@ در تلگرام می‌باشد.
                        </v-alert>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </section>
</template>

<script>
    export default {
        data(){
            return{
                step3:'./assets/img/contact/step3.webp',
            }
        }
    }
</script>