<template>
    <v-footer color="#4c505b" dark inset tile>
        <v-container class="col-12 col-xl-9 col-lg-9">
            <v-row align="center" justify="space-between" class="text-center">
                <v-col cols="12" sm="6">
                    <h4>هفت روز هفته، در 24 ساعت شبانه روز پاسخگوی شما هستیم</h4>
                </v-col>
                <v-col cols="12" sm="6" class="d-flex text-center align-center justify-end">
                    <v-btn :to="{name:'contact-us'}" small outlined>تماس با پشتیانی</v-btn>
                    <v-btn :to="{ name: 'mostQuestion' }" small color="primary" class="mr-4"> سوالات متداول</v-btn>
                  
                </v-col>
            </v-row>
        </v-container>
    </v-footer>
</template>

<script>
    export default {
        
    }
</script>