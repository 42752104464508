<template>
    <section class="banner py-0 my-0">
        <v-container class="col-12 col-xl-9 col-lg-9">
            <v-row class="bgColor" align="center" justify="space-around">
                <v-col cols="12" sm="7">
                    <h3>در سود کریپتوجِت سهیم شوید</h3>
                    <p>با دعوت از دوستانتان با لینک اختصاصی خود، هر بار که معامله مکنید برای همیشه تا 30% از کارمزد معاملاتشان از طرف کریپتوجِت به شما تعلق میگیرد</p>
                    <v-btn class="text-caption pb-1" rounded small elevation="1" color="secondary">بیشتر بدانید</v-btn>
                </v-col>
                <v-col cols="12" sm="5">
                    <v-img :src="bannerImage" />
                </v-col>
            </v-row>
        </v-container>
    </section>
</template>

<script>
    export default {
        data(){
            return{
                bannerImage: './assets/img/profit-img.webp',
            }
        }
    }
</script>