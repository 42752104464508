<template>
    <section>
        <v-row justify="center" align="center">
            <v-col cols="9">
                <div class="text-center white--text mt-n17">
                    <h1>تماس با کریپتوجِت</h1>
                    <h5 class="mt-2">در صورت داشتن هر گونه سوال یا ابهام در مورد کریپتوجِت، می‌توانید از مسیرهای ارتباطی زیر با ما در تماس باشید.</h5>
                </div>
                <v-card height="auto" class="mb-3 mt-3">
                    <v-card-text class="d-flex flex-wrap justify-space-between align-center">
                        <v-col cols="12" sm="6">
                            <div class="d-flex flex-wrap py-4">
                                <v-chip color="primary" class="ml-3 mb-2">مسیر اول</v-chip>
                                <h1 class="secondary--text">پاسخ به پرسش‌های های پرتکرار</h1>
                            </div>
                            <h4 class="black--text pb-4 text-justify">اگر در بخش های مختلف کریپتوجِت سوالی دارید لطفاً قبل از تماس با پشتیبانی، ابتدا پرسش‌های متداول را بخوانید</h4>
                            <v-btn :to="{ name: 'faq' }" color="secondary" small>سوالات متداول</v-btn>
                        </v-col>
                        <v-col cols="auto">
                            <v-img :src="step1" width="200" height="190" />
                        </v-col>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </section>
</template>

<script>
    export default {
        data(){
            return{
                step1:'./assets/img/contact/step1.webp',
            }
        }
    }
</script>