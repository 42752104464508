<template>
    <section>
        <v-container>
            <v-card rounded>
                <v-card-title class="pb-0 mb-0">
                    <v-text-field outlined dense v-model="search" append-icon="mdi-magnify" label="جستجو" />
                </v-card-title>
                <v-data-table :headers="headers" :items="currency" :search="search" :loading="!currency" loading-text="در حال بروز رسانی ... لطفا کمی صبر کنید">
                    <template v-slot:item.buy="{ item }">
                        <p class="fontGreen">{{ item.buy }}</p>
                    </template>
                    <template v-slot:item.sell="{ item }">
                        <p class="fontRed">{{ item.sell }}</p>
                    </template>
                    <template v-slot:item.usd="{ item }">
                        <p>{{ String(parseFloat(item.usd)) }}</p>
                    </template>
                    <template v-slot:item.icon="{ item }">
                        <v-img :src="item.icon" width="32" height="32" />
                    </template>
                    <template v-slot:item.actions="{ item }">
                        <v-btn small outlined color="primary" :href="item.buyurl" class="ml-2">خرید</v-btn>
                        <v-btn small outlined color="secondary"  :href="item.sellurl"  >فروش</v-btn>
                    </template>
                </v-data-table>
            </v-card>
        </v-container>
    </section>
</template>

<script>
    export default {
        mounted(){
            this.getCurrencys()
        },
        data(){
            return{
                currency:[],
                search:'',
                headers:[
                    {text: '', align: 'start', value: 'icon'},
                    {text: 'نماد', align: 'start', value: 'name'},
                    {text: 'قیمت جهانی (به دلار )', value: 'usd'},
                    {text: 'قیمت خرید از ما(تومان)', value: 'buy'},
                    {text: 'قیمت فروش به ما(تومان)', value: 'sell'},
                    {text: '', value: 'actions'},

                ]
            }
        },
        methods:{
            getCurrencys(){
                let temp = []
                this.$store.dispatch('getCurrency')
                .then(resp=>{
                    for (let i = 0; i < Object.entries(resp).length; i++) {
                        const el = Object.entries(resp)[i][1];
                        if(el.icon){
                            temp.push(el)
                        }
                    }
                    this.currency = temp
                    setTimeout(() => {
                        this.getCurrencys()
                    }, 3000);
                })
                .catch(err=>{
                    console.log(err);
                })
            },
        },
        // filters:{
        //     formatNumber(value){
        //         return Number.parseFloat(value).toString();
        //     },
        // },
        // computed:{
        //     searchSymbol(){
        //         if (this.currency) {
        //             return this.currency.filter(item =>{
        //                 return this.search.toLowerCase().split(' ').every(v => item.title.toLowerCase().includes(v))
        //             })
        //         }
        //         else{
        //             return this.currency
        //         }
        //     }
        // }
    }
</script>