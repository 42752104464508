<template>
    <section>
        <v-app-bar tile flat color="backgroundColor">
            <v-row align="center" justify="center" >
                <!-- Right Column -->
                <v-col cols="12" md="8" class=" align-center hidden-sm-and-down" >
                    <v-btn color="transparent" text x-large :to="{ name: 'home' }">
                        <v-img :src="logo" />
                    </v-btn>
                    <!-- Menu Icon -->
                    <v-menu bottom offset-y open-on-hover open-delay="5">
                        <template v-slot:activator="{ on, attrs}">
                            <v-btn icon v-on="on" v-bind="attrs" small color="transparent">
                                <v-icon color="#03c5cc">mdi-format-align-left</v-icon>
                            </v-btn>
                        </template>
                        <v-list>
                            <v-list-item v-for="(item, i) in menuNav" :key="i" class="hover" :href="item.link">
                                <v-list-item-icon>
                                    <v-icon :color="item.color">{{ item.icon }}</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                                    <v-list-item-subtitle>{{ item.subtitle }}</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </v-menu>

                    <v-btn :to="item.link" class="mr-1 pb-1 hover" text v-for="(item, i) in btns" :key="i">
                        {{ item.text }}
                    </v-btn>

                    <v-menu offset-y bottom open-on-hover open-delay="5">
                       
                        <v-list>
                            <v-list-item>
                                <v-list-item-content class="hover">
                                    <v-list-item-title class="py-1">استخراج</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-content class="hover">
                                    <v-list-item-title class="py-1">ایردراپ</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </v-col>
                <!-- Left Column -->
                <v-col cols="12" md="4" class="text-left">
				
              <v-btn class="mr-3 py-0" color="secondary" href="tel:09120123244">0912-0123244 </v-btn> 	
                    <v-btn class="hover pb-1 hidden-md-and-down" href="https://cryptojet.mentorvid.com/" text>ورود</v-btn>
                    <v-btn class="mr-3 py-0" color="primary" href="https://cryptojet.mentorvid.com/register">ثبت نام</v-btn>
                </v-col>
            </v-row>
            <v-app-bar-nav-icon @click.stop="drawer = !drawer" class="hidden-md-and-up" />
        </v-app-bar>
        
        <!-- Navigation Drawer -->
        <v-navigation-drawer v-model="drawer" fixed style="z-index: 1000000;">
            <v-list class="text-center">
                <router-link class="hover" :to="{ name: 'home' }">
                    <v-avatar size="auto" tile class="white">
                        <v-img :src="logo" width="128" height="31" />
                    </v-avatar>
                    <h5>cryptojet</h5>
                </router-link>
                <v-divider color="secondary" />
                <v-list-item>
                    <v-list-item-title>
                        <v-btn icon small color="primary" href="https://cryptojet.mentorvid.com/" class="mx-2"><v-icon>mdi-login</v-icon></v-btn>
                        <v-btn icon small color="secondary" href="https://cryptojet.mentorvid.com/register" class="mx-2"><v-icon>mdi-account-plus</v-icon></v-btn>
                    </v-list-item-title>
                </v-list-item>
                <v-divider color="secondary" />
                <v-list-item v-for="(item, i) in btns" :key="i" link class="hover text-right" :href="item.link">
                    <v-list-item-content>
                        <v-list-item-title>{{ item.text }}</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-icon>
                        <v-icon>{{ item.icon }}</v-icon>
                    </v-list-item-icon>
                </v-list-item>
                <v-divider color="secondary" />
                <v-expansion-panels>
                    <v-expansion-panel v-for="(item, i) in menuNav" :key="i" class="hover">
                        <v-expansion-panel-header :expand-icon="item.icon" disable-icon-rotate>
                            {{ item.title }}
                        </v-expansion-panel-header>
                        <v-expansion-panel-content class="text-right">{{ item.subtitle}}</v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </v-list>
            <template v-slot:append>
                <v-card flat class="d-flex justify-space-around header50">
                    <v-btn v-for="(item, i) in socialMedia" :key="i" icon small :to="item.link">
                        <v-icon :color="item.color">{{ item.icon }}</v-icon>
                    </v-btn>
                </v-card>
            </template>
    </v-navigation-drawer>
    </section>
</template>

<script>
    export default {
        name:'Header',
        data(){
            return{
                logo: './assets/img/logo.svg',
                drawer: false,
                socialMedia:[
                    { color:'#e03172', icon:'mdi-instagram', link:'#'},
                    { color:'#179cf0', icon:'mdi-twitter', link:'#'},
                    { color:'#0073b2', icon:'mdi-linkedin', link:'#'},
                    { color:'#0f8ff2', icon:'mdi-facebook', link:'#'}
                ],
                menuNav:[
                    { link:'about', color:"#03c5cc", icon:"mdi-bitcoin", title:"صرافی cryptojet", subtitle:"صرافی تراکنش ارزهای دیجیتال و محصولات جانبی"},
                    { link:'', color:"#6c6eff", icon:"mdi-connection", title:"زنجیزه هوشمند cryptojet", subtitle:"اکوسیستم زنجیزه تمرکز زدایی شده"},
                ],
                btns:[
                    { icon:'mdi-bitcoin', text:"جدول قیمت", link:'current-prices'},
                    { icon:'mdi-store', text:"سوالی دارید ؟", link:'contact-us'},
                    { icon:'mdi-store', text:"درباره ما", link:'about'},
					{ icon:'mdi-store', text:"قوانین و مقررات", link:'terms'},

				
                ]
            }
        },
    }
</script>