<template>
    <v-container class="col-12 col-xl-9 col-lg-9 pb-10">
        <v-row align="center" justify="center">
            <!-- Exchage columns -->
            <v-col cols="12" sm="7" justify="center" v-if="buy" class="pt-16">
                <ul>
                    <li class="d-flex">
                        <v-text-field class="col-5 pl-1" solo readonly value="تومان" flat outlined disabled />
                        <v-text-field class="col-7" :value="numberFormat(Math.round(price.tomanUnit))" reverse solo placeholder="0" flat outlined readonly />
                    </li>
                    <li class="offset-5 py-0 my-0">
                        <v-btn @click="buy = !buy" color="primary" small fab class="mt-n10 mr-n5" style="z-index: 5;">
                            <v-icon size="60">mdi-sync-circle</v-icon>
                        </v-btn>
                    </li>
                    <li class="d-flex mt-n4">
                        <v-select solo reverse class="col-5 pl-1" flat outlined :items="filterText ? filterCurrencies : localCurrencies" v-model="selectedCoin" @change="updatePrice">
                            <template v-slot:prepend-item>
                                <v-list-item>
                                    <v-list-item-content>
                                        <v-text-field hide-details dense label="جستجو" append-icon="mdi-magnify" v-model="filterText" @input="filterCoin"/>
                                    </v-list-item-content>
                                </v-list-item>
                            </template>
                            <template v-slot:item="{ item, attrs, on}" >
                                <v-list-item v-on="on" v-bind="attrs" class="hover" v-show="item.icon">
                                    <v-list-item-content style="z-index: 1000;">
                                        <v-list-item-title class="d-flex">
                                            <v-avatar size="25">
                                                <v-img :src="item.icon" />
                                            </v-avatar>
                                            <v-spacer />
                                            <span class="textSubtitle">{{ item.text }}</span>
                                        </v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </template>
                        </v-select>
                        <v-text-field dir="ltr" class="col-7" v-model="price.coinUnit" reverse solo value="0" placeholder="0" flat outlined />
                    </li>
                    <li>
                        <v-row class="navigation-text">
                            <v-col cols="12" sm="5" class="mt-n4 px-0">
                                <v-col class="d-flex">
                                    <span class="greenDot ml-1 mt-1" /><span>قیمت خرید {{ selectedCoin.name }}</span>
                                    <span class="mr-5">تومان {{ getBuyCoin() }}</span>
                                </v-col>
                                <v-col class="d-flex">
                                    <span class="redDot ml-1 mt-1" /><span>قیمت فروش {{ selectedCoin.name }}</span>
                                    <span class="mr-3">تومان {{ getSellCoin() }}</span>
                                </v-col>
                            </v-col>
                            <v-col cols="12" sm="7" class="pr-1">
                                <v-btn block x-large color="primary" href="https://cryptojet.mentorvid.com/">خرید</v-btn>
                            </v-col>
                        </v-row>
                    </li>
                </ul>
            </v-col>

            <v-col cols="12" sm="7" justify="center" v-else class="pt-16">
                <ul>
                    <li class="d-flex mb-n4">
                        <v-select solo reverse class="col-5 pl-1" flat outlined :items="filterText ? filterCurrencies : localCurrencies" v-model="selectedCoin" @change="updatePrice">
                            <template v-slot:prepend-item>
                                <v-list-item>
                                    <v-list-item-content>
                                        <v-text-field hide-details dense label="جستجو" append-icon="mdi-magnify" v-model="filterText" @input="filterCoin"/>
                                    </v-list-item-content>
                                </v-list-item>
                            </template>
                            <template v-slot:item="{ item, attrs, on}">
                                <v-list-item v-on="on" v-bind="attrs" class="hover" v-show="item.icon" >
                                    <v-list-item-content style="z-index: 1000;">
                                        <v-list-item-title class="d-flex">
                                            <v-avatar size="25">
                                                <v-img :src="item.icon" />
                                            </v-avatar>
                                            <v-spacer />
                                            <span class="textSubtitle">{{ item.text }}</span>
                                        </v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </template>
                        </v-select>
                        <v-text-field dir="ltr" class="col-7" :value="price.coinUnit" reverse solo placeholder="0" flat outlined readonly/>
                    </li>
                    <li class="offset-5 py-0 my-0">
                        <v-btn @click="buy = !buy" color="primary" small fab class="mt-n3 mr-n5" style="z-index: 100;">
                            <v-icon size="60">mdi-sync-circle</v-icon>
                        </v-btn>
                    </li>
                    <li class="d-flex">
                        <v-text-field class="col-5 pl-1" solo readonly value="تومان" flat outlined disabled />
                        <v-text-field dir="ltr" class="col-7" v-model="price.tomanUnit" reverse solo placeholder="0" flat outlined />
                    </li>
                    
                    <li>
                        <v-row class="navigation-text">
                            <v-col cols="12" sm="5" class="mt-n4 px-0">
                                <v-col class="d-flex">
                                    <span class="greenDot ml-1 mt-1" /><span>قیمت خرید {{ selectedCoin.name }}</span>
                                    <span class="mr-5">تومان {{ getBuyCoin() }}</span>
                                </v-col>
                                <v-col class="d-flex">
                                    <span class="redDot ml-1 mt-1" /><span>قیمت فروش {{ selectedCoin.name }}</span>
                                    <span class="mr-3">تومان {{ getSellCoin() }}</span>
                                </v-col>
                            </v-col>
                            <v-col cols="12" sm="7" class="pr-1">
                                <v-btn block x-large color="secondary" href="https://cryptojet.mentorvid.com/">فروش</v-btn>
                            </v-col>
                        </v-row>
                    </li>
                </ul>
            </v-col>
            
            <!-- Banner column -->
            <v-col cols="12" sm="5">
                <v-img :src="logo" />
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    export default {
        data(){
            return{
                logo: './assets/img/banner_decor.png',
                buy: true,
                localCurrencies: [],
                filterCurrencies: [],
                selectedCoin: null,
                price: {
                    tomanUnit: 0,
                    coinUnit: 0,
                },
                filterText: ''
            }
        },
        props: ['currencies'],
        mounted(){
            this.getCurrencies()
        },
        watch: { 
            currencies: function(newVal, oldVal) {
                this.getCurrencies(newVal)
                for (let index = 0; index < this.localCurrencies.length; index++) {
                    const coin = this.localCurrencies[index];
                    if (coin.value == this.selectedCoin) {
                        if (this.buy)
                            this.price.tomanUnit = this.price.coinUnit * this.trimFarsi(coin.buy)
                        else
                            this.price.coinUnit = this.price.tomanUnit / this.trimFarsi(coin.sell)
                    }
                }
            },
            'price.coinUnit': function(newVal, oldVal) {
                if (this.buy){
                    for (let index = 0; index < this.localCurrencies.length; index++) {
                        const coin = this.localCurrencies[index];
                        if (coin.value == this.selectedCoin) {
                            this.price.tomanUnit = newVal * this.trimFarsi(coin.buy)
                        }
                    }
                }
            },
            'price.tomanUnit': function(newVal, oldVal) {
                if (!this.buy){
                    for (let index = 0; index < this.localCurrencies.length; index++) {
                        const coin = this.localCurrencies[index];
                        if (coin.value == this.selectedCoin) {
                            this.price.coinUnit = newVal / this.trimFarsi(coin.sell)
                        }
                    }
                }
            },

            'searchTerm'(newVal, oldVal){
                this.getLocalCurrencies(newVal)
            }
            
        },
        methods:{
            updatePrice(data){
                this.price.coinUnit = 1
                for (let index = 0; index < this.localCurrencies.length; index++) {
                    const coin = this.localCurrencies[index];
                    if (coin.value == data) {
                        this.price.tomanUnit = this.price.coinUnit * this.trimFarsi(coin.buy)
                    }
                }
            },

            getCurrencies(newList){
                let temp = []
                for (const key in newList) {
                    if (Object.hasOwnProperty.call(newList, key)) {
                        const coin = newList[key];
                        let data = {
                            text: coin.name,
                            value: key,
                            icon: coin.icon,
                            usd: coin.usd,
                            buy: coin.buy,
                            sell: coin.sell
                        }
                        if (
                            typeof data.text !== 'undefined' &&
                            typeof data.value !== 'undefined' &&
                            typeof data.icon !== 'undefined'){
                            temp.push(data)
                        }

                        if (!this.selectedCoin) {
                            this.selectedCoin = data.value
                            this.price.coinUnit = 1
                            this.price.tomanUnit = this.buy ? this.trimFarsi(data.buy) : this.trimFarsi(data.sell)
                        }
                    }
                }
                this.localCurrencies = temp
                if (this.selectedCoin){
                    for (let index = 0; index < temp.length; index++) {
                        const el = temp[index];
                        if (el.value == this.selectedCoin.value) this.selectedCoin = temp[index]
                    }
                }
            },

            trimFarsi(text){
                return text.split(',').join('')
            },

            numberFormat(digits) {
                return (("" + digits).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,"));
            },

            decimalNumberFormat(digits){ 
                digits = digits+"";
                var realVal = digits.substring(0,digits.indexOf("."));
                var decimals = digits.substring(digits.indexOf(".")+1);
                var out = (this.numberFormat(realVal) + "") + (decimals=="" ? "" : "." + decimals);
                if(out.endsWith('.')){
                    out = (this.numberFormat(realVal) + "");
                }
                if(out.startsWith('.')){
                    out = out.substring(1);
                }
                return out; 
            },

            getBuyCoin(){
                for (const key in this.localCurrencies) {
                    if (Object.hasOwnProperty.call(this.localCurrencies, key)) {
                        const data = this.localCurrencies[key];
                        if(this.selectedCoin == data.value) return data.buy
                    }
                }
            },

            getSellCoin(){
                for (const key in this.localCurrencies) {
                    if (Object.hasOwnProperty.call(this.localCurrencies, key)) {
                        const data = this.localCurrencies[key];
                        if(this.selectedCoin == data.value) return data.sell
                    }
                }
            },
            
            filterCoin () {
                var temp = []
                for (let index = 0; index < this.localCurrencies.length; index++) {
                    const coin = this.localCurrencies[index];
                    try {
                        if (
                            coin.text.toLowerCase().indexOf(this.filterText.toLowerCase()) > -1 ||
                            coin.value.toLowerCase().indexOf(this.filterText.toLowerCase()) > -1
                        ) { temp.push(coin) }
                    } catch (error) {
                        console.log(error);
                    }
                }
                this.filterCurrencies = temp
            }
        }
    }
</script>