<template>
    <section>
        <Header />
        <v-row justify="center" align="center">
            <v-col cols="9" class="mt-n16">
                <h1 class="text-center white--text mb-3">قوانین و مقررات  </h1>
                <v-card outlined flat class="mb-6">
                    <v-card-title class="secondary--text">
                        <v-icon color="secondary" large class="ml-3">mdi-map-marker-question-outline</v-icon>قوانین و مقررات 
                    </v-card-title>
                    <v-card-text>
                        <v-expansion-panels focusable>
						<p>این متن قوانین و قرارداد بین سایت اینترنتی کریپتوجِت (CryptoJet.com)و هر کاربری است که به نحوی از خدمات مختلف سایت استفاده می کند. در این قرارداد حقوق سایت کریپتوجِت و شرایط استفاده از آن و حقوق کاربر قید شده است. کاربر سایت کریپتوجِت باید این قوانین را به طور کامل بخواند و متوجه شود و بپذیرد. این حق برای سایت کریپتوجِت محفوظ است که در هر زمان که بخواهد مفاد این قرارداد را تغییر دهد.

استفاده از هرگونه امکانات کریپتوجِت - ثبت نام - خرید - فروش و هر گونه امکانات دیگر به این معنی است که شما مفاد این قرارداد را کاملا خوانده و فهمیده و قبول کرده اید. اگر با مفاد این قرارداد موافق نیستید حق استفاده از امکانات کریپتوجِت را ندارید.

طرفین قرارداد سایت اینترنتی کریپتوجِت دات نت و به عنوان دهنده ی خدمات و کاربر سایت به عنوان گیرنده ی خدمات می باشند.

مفاد قرارداد:
<br>
تعاریف و واژگان:
کریپتوجِت سایت اینترنتی می باشد که تحت همین دامنه CryptoJet از طریق اینترنت قابل دسترس می باشد. این سایت برای کاربران ایرانی تحت قوانین جمهوری اسلامی ایران هستند و از کلیه ی قوانین جمهوری اسلامی ایران تبعیت می کند.
کاربر کسی است که بنابه اراده ی خود از سایت کریپتوجِت دات نت بازدید می کند و بدون هیچ گونه اجبار از خدمات این سایت استفاده می کند.
ارز الکترونیکی مانند وب مانی - پی پال - پرفکت مانی - بیت کوین یک ابزار مالی بدون بهره است که مورد خرید فروش قرار می گیرد و از آن می توان در سایت های پذیرنده به عنوان یک حواله ی پولی یا ژتون ارزش دار استفاده کرد.
ارز الکترونیکی مورد قبول آنهایی است که در سایت کریپتوجِت لیست شده و مورد پذیرش سایت کریپتوجِت برای خرید و فروش می باشد.پرداخت وجه عبارت است از انتقال وجه - ریالی - یا به صورت معادل ارز الکترونیکی از پرداخت کننده ی وجه به گیرنده ی وجه.
روش پرداخت عبارت است از هر روشی که برای انتقال وجه در کریپتوجِت مجاز شمرده شده است. پرداخت می تواند توسط ارز الکترونیکی یا انتقال بانکی حضوری یا اینترنتی یا توسط کارتهای شتابی از درگاه بانکی کریپتوجِت و یا انتقال بین المللی بانکی از طریق سوییفت یا دیگر روش های پرداخت باشد.
<br><br>
کریپتوجِت خدمات زیر را برای کابر فراهم می سازد:
<br>
تبدیل یک ارز الکترونیکی به ارز الکترونیکی دیگر با نرخ تبدیل معین و کارمزد معین و حداقل و حداکثر معین.
<br>
فروش رمز ارزبه کاربر و دریافت مبلغ معینی وجه پول رایج از وی.
<br>
خرید رمز ارز از کاربر و پرداخت مبلغ معینی وجه رایج به وی.
<br>
خرید و فروش و مبادله ارز دیجیتال کاربر با کاربر با کارمزد معین.
<br>
کریپتوجِت خدمات خود را برای هر ارز الکترونیکی که در سایت لیست شده است و ممکن است طبق شرایط مختلف و به دلخواه کریپتوجِت تغییر نماید به هر کاربر که بخواهد و منع قانونی نداشته باشد ارائه می کند.
با استفاده از خدمات کریپتوجِت کاربر می پذیرد که:
<br>
کاربر می پذیرد که منبع و مقصد کلیه ی ارزهای الکترونیکی و غیر الکترونیکی ارائه شده توسط وی در تراکنش های سایت کریپتوجِت کاملا قانونی و مطابق با قوانین بین المللی و مقررات جمهوری اسلامی ایران باشند.<br>
کاربر می پذیرد که مالک قانونی وجوه و حساب هایی است که وی در هر سفارش به هر نحو چه برای پرداخت و چه برای دریافت از آنها استفاده کرده می باشد.<br>
کریپتوجِت از ارائه خدمات به اشخاص با هویت پنهان و احراز نشده، معذور است.<br>
حداقل سن برای ثبت نام ، احراز هویت و فعالیت در کریپتوجِت 18 سال تمام می باشد.<br>
قرار دادن حساب کاربری خود در اختیار اشخاص دیگر ( حساب بانکی اجاره ای ، اطلاعات کاربری و...) درصورت محرز شدن , موجب مسدود شدن حساب کاربری ، و هرگونه عواقب ناشی این امر بر عهده کاربر می باشد.
قوانین در موارد زیادی و بسته به شرایط و قوانین جمهوری اسلامی ممکن است تغییر کند و وب سایت اطلاع رسانی در موارد تغییر اعلام می نماید و کاربر موظف به مطالعه آن ها در زمان هر خرید می باشد.
کاربر موظف است قبل از ورود به درگاه و پرداخت ارز یا ریال خود ، پیش فاکتور ارائه شده را با دقت مطالعه کند و با آگاهی کامل سفارش و قوانین را تایید و وارد درگاه شود . در صورت اعتراض به کسر کارمزد یا نا آگاهی ، سایت کریپتوجِت هیچ مسئولیتی درباره اشتباهات کاربر را نمی پذیرد.
کاربر موظف است از طریق کارت هایی که در سایت ثبت و تایید شده اند خرید نماید ، در صورت خرید با کارت ثبت نشده و تاخیر در انجام تراکنش متوجه کاربر خواهد بود و کریپتوجِت هیچ مسئولیتی در این مورد ندارد.
درصورت خرید با کارت های بانکی غیر مجاز که متعلق به شما نباشد و یا برگشت وجوه به حساب مبدا کاربر ، کریپتوجِت موظف به دریافت اطلاعات حساب اصلی و صاحب حساب و (در صورت صلاحدید ، کریپتوجِت میتواند درخواست نامه قضایی داشته باشد) و عودت وجه طی 48 تا 72 ساعت کاری بعد از دریافت اطلاعت می باشد.
کاربر موظف است سفارش خود را با دقت کامل ثبت نماید ، در صورت هرگونه اشتباه، امکان لغو ، برگشت وجه یا ارز امکان پذیر نمی باشد.
در صورت واریز مبلغ یا مقدار متفاوت با سفارش از طرف کریپتوجِت یا کاربر (مقدار بیشتر یا کمتر) ، و یا واریز به ادرس متفاوت با سفارش ، هردو طرف بدون قید و شرط موظف به اصلاح و تسویه تراکنش بر طبق سفارش می باشند.
هر ادرس کیف پول های رمز ارز کاربر فقط و فقط متعلق به آن رمز ارز می باشد و به علت شباهت ادرس بعضی رمز ارزها به یگدیگر، در صورت واریز اشتباه رمز ارز دیگری به ادرس مشخص شده ، تمام مسئولیت واریز اشتباه بر عهده کاربر می باشد و کریپتوجِت هیچ مسئولیتی در قبال اشتباه واریز کاربر ندارد.
به دستور پلیس فتا بعد از اولین خرید و یا واریز ریال ، تا 24 ساعت کاری امکان برداشت وجود ندارد.<br>
کاربران جدید از اولین خرید یا فروش تا سه مرحله ترید در هر مرحله تا سقف مبلغ 20میلیون تومان میتوانند ترید کنند.<br>
اگر مبلغ واریزی کاربر به سایت ( چه ریالی و چه رمز ارز و..) بعد از محدوده ی زمانی سفارش باشد ، به هیچ عنوان تراکنش قبلی فعال نخواهد شد.<br>
سفارش هایی که از طرف سایت انجام شده و شماره تراکنش یا پیگیری آن ثبت شده است به منزله تکمیل سفارش می باشد و از سوی سایت انجام شده است. در صورتی که ادرس کیف پول ارز، تگ و ممو آدرس یا شماره شبای ارسالی اشتباه باشد، مسئولیت آن متوجه خود کاربر می باشد و سایت کریپتوجِت هیچگونه تعهدی نسبت به ارسال مجدد، تاخیر، برگشت یا ارسال تصویر سفارش را ندارد.
کاربر برای دریافت ریال ، حتما بایستی شماره شبای خود را ثبت کنند، در صورت ثبت شماره شبا به اسم افراد دیگر به هیچ عنوان واریز نمیگردد و در غیر این صورت مسئولیت واریز وجه با خود کاربر می باشد.
کریپتوجِت واریز ریالی به حساب کاربران را تا یک روز کاری در سه بازه ی زمانی در ساعات اداری ارسال و شماره پیگیری در تراکنش مربوطه درج می شود. قابل ذکر است مشکلات غیر قابل پیشبینی از قبیل اشکال در شبکه شاپرک و بانکی در این بازه ی زمانی تسویه تاثیر گذار می باشد.
در صورتی که واریز های ریالی برای کاربران به علت محدودیت های بانکی ، سقف واریزی و دلایل دیگر با تاخیر زمانی انجام شود ، کاربر نمی تواند غیر از ادعای عدم دریافت ریال از سوی کریپتوجِت ، هیچگونه ادعا و یا درخواست دیگری از قبیل لغو کردن تراکنش و یا درخواست مبلغ بیشتری نماید.
در صورتی که کاربر تراکنشی ایجاد کند ( هر نوع تراکنشی) و در بازه ی زمانی مشخص شده ، ارز مبدا خود را واریز نکند ( مثلا اگر در مدت 20 دقیقه یا هر بازه ی زمانی که سایت در تراکنش اعلام می نماید باید به ادرس مشخص شده بیت کوین واریز کند) ، کریپتوجِت ارز واریز شده در زمان غیر مجاز را با کسر کارمزد آن ارز در سایت کریپتوجِت ، به کاربر برگشت میزند.
به صورت معمول واریز ارزی در ساعات کاری (9الی21) حداکثر 45 دقیقه بعد از ثبت و تایید تراکنش ، در ساعات غیر کاری (21الی24) و تعطیلات رسمی حداکثر تا 2 ساعت بعد از ثبت و تایید تراکنش از سمت کاربر ، انجام می شود.
سعی می شود تمام تراکنش ها در سریعترین زمان و بدون وقفه انجام شود و گاها با توجه با بالارفتن حجم سفارشات و ترافیک شبکه ممکن است با تاخیر انجام گردد.
در صورتی که کاربری چندین سفارش دریافت ریال در روز داشته باشد امکان دارد تمام واریز ها در قالب یک تراکنش ریالی برای شبای مقصد واریزشود.
فرآیند احراز هویت شامل گردآوری اطلاعاتی از کاربر از جمله شماره تماس ثابت - آی پی - کارت شناسایی با تصویر - کد ملی و اطلاعات بانکی وی می باشد.این اقدامات حریم شخصی وی را نقض نخواهد کرد و به هیچ شخص ثالثی ارائه نخواهد شد مگر با نامه ی قضایی و یا درخواست کتبی پلیس محترم فتای جمهوری اسلامی ایران.
سایت کریپتوجِت مجاز است برای اطمینان از صحت تراکنش ها از کاربران اطلاعات بیشتری برای احراز هویت شخص و تراکنش های کاربر را درخواست کند.
<br>
در صورت شناسایی و تشخصی رفتار غیر متعارف و غیر واقعی کاربر در رابطه با فعالیت در سایت در هر زمینه ای ، کریپتوجِت موظف به اخطار به کاربر و مسدود سازی حساب وی می باشد .
نرخ تبدیل و قیمت تبدیل ارزهای الکترونیکی مختلف توسط کریپتوجِت تعیین می شوند و در روی سایت توسط کلیه ی کابران قابل مشاهده خواهدبود و در هنگام سفارش نیز مبلغ دریافتی کاربر بعد از تکمیل سفارش با کسر کارمزد ها و با اعمال نرخ تبدیل به طور کامل و شفاف و دقیق به وی نمایانده می شوند و کارمزد های دریافتی در فاکتور ارتباطی با کارمزد های شبکه ندارند.
تصمیم راجع به موفق یا ناموفق بودن احراز هویت فقط و فقط در اختیار کریپتوجِت خواهد بود و سایت کریپتوجِت می تواند یک احراز هویت را به تشخیص خود قبول کند یا قبول نکند.
<br>
هر گونه کارمزد ، کمیسیون و هزینه ی دیگر که سیستم یا ارز الکترونیکی به کاربر تحمیل نماید فقط و فقط بر عهده ی کاربر خواهد بود و کریپتوجِت هیچ گونه مسئولیتی راجع به این گونه هزینه ها نمی پذیرد.
واریز معادل ارزهای خریداری شده در اولین و سریعترین زمان در ساعات اداری واریز می شوند. در ساعات غیر اداری ممکن است واریز به کمی تاخیر انجام شود. واریز معادل آلت کوین ها (اتریوم ، ریپل و...) ممکن است زمان بیشتری طول بکشند.
معادل ریالی ارزهای فروخته شده به کریپتوجِت در اولین روز اداری، روزانه سه بار از طریق سیستم پایا واریز می گردد و کاربران با توجه با بازه ی زمانی بانک حساب خودشان معادل ریالی را دریافت می کنند. 
<br>
در ساعات غیر اداری یا تعطیل واریز ریالی انجام نمی شود و در شروع اولین روز کاری در لیست واریزی ها قرار می گیرند.
کریپتوجِت هیچ گونه مسئولیتی راجع به تاخیر، زمان بندی سایت مقصد در دریافت ، و یا تراکنش ناموفق ایجاد شده در انجام سفارش به علت نقص یا مشکل یا تعمیرات سیستم ارز الکترونیکی یا بانک پذیرنده نمی پذیرد.
اگر تحت شرایطی کریپتوجِت ، اشتباهاً وجوه یا ارزی را به حساب کاربر منظور یا در محاسبات خود هر نوع اشتباهی نماید، هر زمان مجاز است ؛مستقلاً و بدون انجام هیچ گونه تشریفات و قضائی و دریافت اجازه کتبی از متعهد (صاحب حساب) در رفع اشتباه و برداشت از حساب‌های وی اقدام نماید و تشخیص کریپتوجِت نسبت به وقوع اشتباه یا پرداخت بدون حق و لزوم برداشت از حساب معتبر خواهد بود.
بعد از ثبت نام در کریپتوجِت امکان تغییر آدرس ایمیل ، نام کاربری و سایر موارد ثبت شده وجود نخواهد داشت ، لطفا در زمان ثبت نام دقت کافی به عمل آورید .
<br>
کریپتوجِت هیچگونه ارتباطی با سایت ها و یا ابزارهای شرط بندی ندارد و در صورت استفاده کاربران با اینگونه سایت ها ، حساب کاربری ایشان مسدود خواهد شد.
کریپتوجِت هیچ تحلیل ، پیش بینی و سیگنالی را تایید نمی کند. تحلیل ارائه شده از بازار صرفا بر اساس پیش بینی انجام شده است و هیچگونه قطعیتی بر وقوع آن وجود ندارد و کریپتوجِت هیچ تعهدی نسبت به انجام و به نتیجه رسیدن آن نخواهد داشت.
<br>
<br>
<br>
کاربر می پذیرد که کلیه ی فعالیت های وی و سایت کریپتوجِت تحت قوانین جمهوری اسلامی ایران است و فعالیت های وی در هر صورت هر شکل نباید قوانین جمهوری اسلامی ایران را نقض کند ، در صورت نقض قوانین ، سوء استفاده مالی و غیر مالی و هرگونه فعالیت مشکوک ، حساب کاربری فرد مربوطه مسدود و اطلاعات هویتی و مالی کاربر از طریق مراجع قانونی پیگیری قضایی و کاربر بدون قید و شرط متعهد به جبران خسارت خواهد بود.
هر گونه پیامدهای مالیاتی ناشی از تراکنش های کاربر با کریپتوجِت به عهده ی خود کاربر خواهد بود و کریپتوجِت هیچ گونه مسئولیتی نمی پذیرد.
کریپتوجِت می تواند قوانین و مقررات مربوطه رو در هر زمان تغییر دهد و کاربر همواره موظف است از اخرین تغییرات قوانین آگاهی کامل را داشته باشد.


کاربر می پذیرد که جز در مورد انجام صحیح سفارش طبق قیمت و گزارش تراکنش مشخص و شفاف و کارمزد مشخص و شفاف طبق مطالب فاکتور خرید یا فروش ارائه شده و بند های قرارداد و قوانین فوق هیچ گونه داعیه و طلب و شکایت دیگر عملا و لسانا از سایت کریپتوجِت (CryptoJet.com) - مدیران - کارمندان - و کلیه ی مرتبطان با این سایت نداشته با این سایت نداشته باشد
</p>

                        </v-expansion-panels>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </section>
</template>

<script>
import Header from './Header.vue'
    export default {
        components:{Header},
        data(){
            return{
                questions:[
                    {
                        title:'قوانین',
                        body:'صرافی ارز دیجیتال کریپتوجِت، یک پلتفرم معامله را آماده کرده‌است که کاربران می‌توانند با ثبت‌نام و احراز هویت در آن، به خرید و فروش ارزهای دیجیتال بپردازند و دارایی خود را در کیف پول اختصاصی خود مدیریت کنند. هدف این است که کاربران ایرانی بتوانند در کمال آسانی و امنیت، بدون واسطه، با یک‌دیگر به معامله بپردازند. تبدیل با پشتیبانی از بیشترین رمزارز در بازار معاملاتی ایران و ارائه‌کردن ابزارهای معاملاتی حرفه‌ای، آمده‌است که نیاز کاربران ایرانی را مرتفع کند تا از دشواری‌های صرافی‌های خارجی و محدودیت‌ها خلاص شوند. کاربران در کریپتوجِت می‌توانند به‌راحتی واریز و برداشت تومانی و خرید و فروش ارز دیجیتال داشته‌باشند.'
                    },
                    {
                        title:'چرا ارز دیجیتال بخریم ؟',
                        body:'سرمایه گذاری در ارز دیجیتال می‌تواند بازار بسیار ساده اما جذاب و پرسودی باشد. ارزهای دیجیتال روز به روز در حال رخنه به دنیای پیرامون ما هستند. دور از انتظارنیست که در آینده ای نزدیک همه تراکنش های مالی و مبادلات صرفا با استفاده از ارزهای دیجیتال صورت بگیرد و تعداد بیشتری از افراد جامعه مشغول به مبادله و خرید و فروش ارز دیجیتال شوند. نکته مثبت و مهم دیگر در خصوص سرمایه گذاری و خرید ارز دیجیتال نقدینگی بسیار بالای این ارزها است. شما میتوانید هر ارز دیجیتالی که در اخیتار دارید را در زمان بسیار کمی به فروش برسانید. یکی دیگر از مزیت های خرید ارز دیجیتال بازدهی بسیار خوب این نوع از سرمایه گذاری است. بازدهی که به خصوص امسال با اختلاف بسیار زیاد از طلا و بورس قرار گرفته است. از دیگر نکات مثبت سرمایه گذاری در ارزهای دیجیتال، انعطاف پذیری این بازار و تنوع بالای موجود در ارزهای دیجیتال است. شما می‌توانید از میان هزاران رمزارز و ارز دیجیتال، چند ارز دیجیتال مورد علاقه خود را انتخاب کرده و یک پرتفوی و سبد متنوع از انواع ارزهای دیجیتال برای خود ایجاد کنید که شانس موفقیت و سوددهی را برای خود بیشتر کنید و در کریپتوجِت به خرید و فروش ارز دیجیتال و مبادله ی آن بپردازید.'
                    },
                    {
                        title:'امنیت در کریپتوجِت چگونه است؟',
                        body:'امنیت تمام و کمال تراکنش‌ها و دارایی‌های شما در کریپتوجِت برعهده شبکه ای پیچیده متشکل از نخبگان دانشگاهی  است. امنیتی که در طی چند سال فعالیت همیشه در بالاترین سطح بوده است. کریپتوجِت برای تأمین امنیت دارایی‌های دیجیتال شما از رویکردهای مختلفی همچون ذخیره‌سازی به شیوه سرد و در کیف پول اختصاصی استفاده می‌کند.'
                    },
                    {
                        title:'چطور شروع کنم؟',
                        body:'کار با کریپتوجِت بسیار ساده است. ابتدا با استفاده از موبایل یا ایمیل شخصی یک حساب کاربری ایجاد نمایید. پس از احراز هویت، می‌توانید مبلغ ریال یا رمزارز مورد نظر خود را به کیف پول داخلی واریز نموده و اقدام به خرید و فروش به صورت آنلاین نمایند.'
                    },
                    {
                        title:'چگونه خرید کنم؟',
                        body:'پس از افتتاح حساب در سایت کریپتوجِت، احراز هویت و تأیید حساب، کاربران محترم می توانند با استفاده از درگاه بانکی اقدام به شارژ حساب ریالی خود نمایند. پس از شارژ حساب، بلافاصله با استفاده از سیستم هوشمند و اتوماتیک  می توانند سفارش گذاری نموده خرید خود را تکمیل نمایند. پس از تکمیل خرید، کیف پول مربوط به ارز دیجیتال خریداری شده به صورت اتوماتیک شارژ خواهد شد. '
                    },
                    {
                        title:'چگونه بفروشم ؟ ',
                        body:'پس از افتتاح حساب در سایت کریپتوجِت، احراز هویت و تأیید حساب توسط تیم امنیتی سایت، کاربران محترم می توانند با استفاده از آدرس اختصاصی، اقدام به انتقال ارز دیجیتال به کیف پول خود نمایند. پس از تأیید تراکنش در شبکه بلاک‌چین (blockchain)،کیف پول مربوط به رمزارز انتقال یافته به صورت اتوماتیک شارژ خواهد شد. پس از این مرحله، این امکان وجود دارد تا کاربر، درخواست فروش رمزارز مورد نظر خود را با قیمت آنلاین و حجم دلخواه ثبت نماید و فروش در لحظه و با سرعت صورت می پذیرد.'
                    },
                    {
                        title:'پشتیبانی کریپتوجِت؟',
                        body:'پشتیبانی خدمات کریپتوجِت به صورت آنی و از طریق تیکت،تلفن ثابت،چت آنلاین ، شبکه های اجتماعی و... پاسخگوی شما می باشد'
                    },
                   
                ]
            }
        }
    }
</script>